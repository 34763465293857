import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './style/Profile.css';
import './style/style.css'
import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ReactStars from 'react-rating-stars-component';
import { Modal, Button } from 'react-bootstrap';
import useSession from './useSession';


function Profile() {
    const [user, setUser] = useState('');    
    const navigate = useNavigate();    
    const [isHalf, setIsHalf] = useState(false); 
    const [loginStatus, setLoginStatus] = useState(''); 
    
    axios.defaults.withCredentials = true;

    /***** Start Vérification de la Session *****/ 
    const sessionData = useSession();

    if (sessionData) {
        // console.log('Utilisation des données de session:', sessionData.email);       
        axios.get(`https://apprating.dev0app.com/profile/${sessionData.email}`)
            // .then(response => response.json())
            // .then(data => setUser(data))
            .then(res => {                
                setUser(res.data[0]);                                   
            })
          .catch(error => console.error('Erreur lors de la récupération des informations de l\'utilisateur:', error));
    }
    else {
        console.log('Aucune session active');   
        navigate('/');           
    }
    /***** End Vérification de la Session *****/ 

    const [ratings, setRatings] = useState({
        gout: 0,
        amabilite: 0,
        communication: 0,
    });
    const [comment, setComment] = useState('');    

    const handleRatingChange = (category, value) => {
        setRatings(prevRatings => ({
            ...prevRatings,
            [category]: value,
        }));
    };

    const handleCommentChange = (event) => {
        setComment(event.target.value);
    };

    const [showModal, setShowModal] = useState(false);
    const [messageSent, setMessageSent] = useState(false);
    
    const handleSubmit = (event) => {
        event.preventDefault(); 
        setShowModal(true);
    };    

    // useEffect(() => {
    //     axios.get('https://apprating.dev0app.com/profile')
    //         .then(res => {
    //             if(res.data.valid){
    //                 console.log(res.data.user);
    //                 setUser(res.data.user);
    //                 //console.log(res.data.user);
    //             }
    //             else {
    //                 navigate('/');
    //             }                
    //         })
    //         .catch(error => {                
    //             console.error('Error fetching profile:', error);             
    //         });
    // }, []);
   

    // useEffect(() => {
    //     axios.get('https://apprating.dev0app.com/protected-route')
    //     .then(res => {
    //         if(res.data.loggedIn == true){
    //             setLoginStatus(res.data.user);
    //             //console.log(res.data.user.name);
    //         }
    //         else {
    //             navigate('/');
    //             console.log(res);
    //         }            
    //     })
    //     .catch(error => {
    //         console.error("Erreur lors de la vérification de la session :", error);
    //         // Rediriger vers une page d'erreur ou de connexion
    //         navigate('/');
    //     });
    // }, []);  
    

    const handleOKButtonClick = () => { 
        setMessageSent(true); 
        const userid = user.idUser;
        if (messageSent) {           
            axios.post('https://apprating.dev0app.com/reviews', {ratings, comment, userid })
            .then(res => {              
                if(ratings.gout < 3 || ratings.amabilite < 3 || ratings.communication < 3 ){
                    const ratingID = res.data.ratingID;                 
                    navigate('/validation', { state: { ratingID } }); 
                }
                else {
                    navigate('/remercier');                
                    setShowModal(false); 
                }                
            })
    
            .catch(error => {
                console.error('Error submitting message:', error);
            });  
        }
    };

    const handleModalClose = () => {
        setMessageSent(false);
        // initaliser les champs
        setRatings({
            gout: 0,
            amabilite: 0,
            communication: 0,
        });
        setComment('');
        setShowModal(false);

        /*setShowModal(false);
        if (messageSent) {
            // Reset form fields if the message was successfully sent
            setRatings({
                gout: 0,
                amabilite: 0,
                communication: 0,
            });
            setComment('');
            setMessageSent(false);
        }*/
    };

    return (
        // <div>            
        //     <h1>Profile</h1>
        //     <p>Name: {name.name}</p>
        //     <img src={`https://apprating.dev0app.com/${name.profile_picture}`} className="rounded-circle" alt={`${name.name}'s profile picture`} style={{ maxWidth: '100px', margin: '0 auto 10px' }} />
        //     <p>Username: {name.username}</p>
        //     <p>Email: {name.email}</p>
        //     <h2>Add Reviews</h2>
        //     <form onSubmit={handleSubmit}>
        //         <div className="form-group">
        //             <div className='flex-cont'>
        //                 <label>Gout</label>
        //                 <ReactStars
        //                     key={`stars_${ratings.gout}`}
        //                     value={Math.max(0, ratings.gout)}
        //                     count={5}
        //                     onChange={(value) => handleRatingChange('gout', value)}
        //                     size={24}
        //                     isHalf={true}
        //                     emptyIcon={<i className="far fa-star"></i>}
        //                     halfIcon={<i className="fa fa-star-half-alt"></i>}
        //                     fullIcon={<i className="fa fa-star"></i>}
        //                     activeColor="#ffd700"
        //                 />
        //             </div>
        //         </div>
        //         <div className="form-group">
        //             <div className='flex-cont'>
        //                 <p>Amabilite</p>
        //                 <ReactStars
        //                     key={`stars_${ratings.amabilite}`}
        //                     value={Math.max(0, ratings.amabilite)}
        //                     count={5}
        //                     onChange={(value) => handleRatingChange('amabilite', value)}
        //                     size={24}
        //                     isHalf={true}
        //                     emptyIcon={<i className="far fa-star"></i>}
        //                     halfIcon={<i className="fa fa-star-half-alt"></i>}
        //                     fullIcon={<i className="fa fa-star"></i>}
        //                     activeColor="#ffd700"
        //                 />
        //             </div>
        //         </div>
        //         <div className="form-group">
        //             <div className='flex-cont'>
        //                 <p>Communication</p>
        //                 <ReactStars
        //                     key={`stars_${ratings.communication}`}
        //                     value={Math.max(0, ratings.communication)}
        //                     count={5}
        //                     onChange={(value) => handleRatingChange('communication', value)}
        //                     size={24}
        //                     isHalf={true}
        //                     emptyIcon={<i className="far fa-star"></i>}
        //                     halfIcon={<i className="fa fa-star-half-alt"></i>}
        //                     fullIcon={<i className="fa fa-star"></i>}
        //                     activeColor="#ffd700"
        //                 />
        //             </div>
        //         </div>                

        //         <div className="form-group form">
        //             <label className='lab-form' htmlFor="commentaire">Commentaire (facultatif)</label>
        //             <textarea className="form-control" id="commentaire" rows="3" placeholder="Écrire un commentaire" value={comment} onChange={handleCommentChange}></textarea>
        //         </div>
        //         <button type="submit" className="btn btn-primary">Soumettre</button>
        //     </form>


        //     <form>
        //             <div className="form-group">
        //                 <div className='flex-cont'>
        //                     <label className='lab' htmlFor="qualiteGout">Qualité goût</label>
        //                     <div>
        //                         {[...Array(5)].map((star, index) => {
        //                             const ratingValue = index + 1;
        //                             return (
        //                                 <label key={ratingValue}>
        //                                     <input type="radio" name="ratingGout" value={ratingValue} onClick={() => setRatingGout(ratingValue)} style={{ display: 'none' }} />
        //                                     <FontAwesomeIcon icon={fasFaStar} style={{ cursor: 'pointer', color: ratingValue <= ratingGout ? '#fdd84b' : 'gray' }} />
        //                                 </label>
        //                             );
        //                         })}
        //                         <span className='spa'> {ratingGout ? `${ratingGout}/5` : '0/5'}</span>
        //                     </div>
        //                 </div>
        //             </div>

        //             {/* Section Amabilité */}
        //             <div className="form-group">
        //                 <div className='flex-cont'>
        //                     <label className='lab' htmlFor="amabilite">Amabilité</label>
        //                     <div>
        //                         {[...Array(5)].map((star, index) => {
        //                             const ratingValue = index + 1;
        //                             return (
        //                                 <label key={ratingValue}>
        //                                     <input type="radio" name="ratingAmabilite" value={ratingValue} onClick={() => setRatingAmabilite(ratingValue)} style={{ display: 'none' }} />
        //                                     <FontAwesomeIcon icon={fasFaStar} style={{ cursor: 'pointer', color: ratingValue <= ratingAmabilite ? '#fdd84b' : 'gray' }} />
        //                                 </label>
        //                             );
        //                         })}
        //                         <span className='spa'> {ratingAmabilite ? `${ratingAmabilite}/5` : '0/5'}</span>
        //                     </div>
        //                 </div>
        //             </div>

        //             {/* Section Communication */}
        //             <div className="form-group">
        //                 <div className='flex-cont'>
        //                     <label className='lab' htmlFor="communication">Communication</label>
        //                     <div>
        //                         {[...Array(5)].map((star, index) => {
        //                             const ratingValue = index + 1;
        //                             return (
        //                                 <label key={ratingValue}>
        //                                     <input type="radio" name="ratingCommunication" value={ratingValue} onClick={() => setRatingCommunication(ratingValue)} style={{ display: 'none' }} />
        //                                     <FontAwesomeIcon icon={fasFaStar} style={{ cursor: 'pointer', color: ratingValue <= ratingCommunication ? '#fdd84b' : 'gray' }} />
        //                                 </label>
        //                             );
        //                         })}
        //                         <span className='spa'> {ratingCommunication ? `${ratingCommunication}/5` : '0/5'}</span>
        //                     </div>
        //                 </div>
        //             </div>

        //             <div className="form-group form">
        //                 <label className='lab-form' htmlFor="commentaire">Commentaire (facultatif)</label>
        //                 <textarea className="form-control" id="commentaire" rows="3" placeholder="Écrire un commentaire"></textarea>
        //             </div>
        //             <button type="submit" className="btn btn-primary">Soumettre</button>
        //         </form>

            

        //     {/* Modal */}
        //     {/* {showModal && (
        //         <div className="modal">
        //             <div className="modal-content">
        //                 <span className="close" onClick={closeModal}>&times;</span>
        //                 <p>{submissionStatus === 'success' ? 'Message submitted successfully!' : 'Failed to submit message.'}</p>
        //             </div>
        //         </div>
        //     )} */}

        //     <Modal show={showModal} onHide={() => setShowModal(false)}>
        //         <Modal.Header closeButton>
        //             <Modal.Title>Success</Modal.Title>
        //         </Modal.Header>
        //         <Modal.Body>
        //             Message successfully sent!
        //         </Modal.Body>
        //         <Modal.Footer>
        //             <Button variant="secondary" onClick={() => setShowModal(false)}>
        //                 Close
        //             </Button>
        //         </Modal.Footer>
        //     </Modal>
        // </div>
        <section className='d-flex text-center justify-content-center align-items-center' style={{ height: '100vh', justifyContent: 'flex-start', backgroundImage: 'url("bg-profile.png")', backgroundSize: '100%', backgroundPosition: 'center top', backgroundRepeat: 'no-repeat' }}>
            <div className='container d-flex justify-content-center flex-column' style={{ height: '100%'}}>
                <div className='row justify-content-center'>
                    <div className='col-md-6 text-center mb-5'>
                        <div className="picture-border">
                            <img src={`https://apprating.dev0app.com/uploads/${user.profile_picture}`} className="rounded-circle" alt={`${loginStatus.name}'s profile picture`} style={{ maxWidth: '150px', margin: '3px auto 10px' }} />
                        </div>
                        <h2 className='nameuser'>{user.name}</h2>
                    </div>
                </div>                
                <div className='col-md-10 offset-md-1 text-center'>
                    <form onSubmit={handleSubmit}>
                        <div className='mb-5'>
                            <div className="form-group">
                                <div className='d-flex justify-content-between'>
                                    <label className='lab' htmlFor="qualiteGout">Qualité goût</label>
                                    <div className='d-flex align-items-center'>
                                        <ReactStars
                                            key={`stars_${ratings.gout}`}
                                            value={Math.max(0, ratings.gout)}
                                            count={5}
                                            onChange={(value) => handleRatingChange('gout', value)}
                                            size={24}
                                            isHalf={true}
                                            emptyIcon={<FontAwesomeIcon icon={faStar} />}
                                            halfIcon={<FontAwesomeIcon icon={faStarHalfAlt} />}
                                            fullIcon={<FontAwesomeIcon icon={faStar} />}
                                            activeColor="#ffd700"
                                            classNames="ratingGout"
                                        />
                                        <span className='spa'> {ratings.gout ? `${ratings.gout}/5` : '0/5'}</span>
                                    </div> 
                                </div>
                            </div>

                            {/* Section Amabilité */}
                            <div className="form-group">
                                <div className='d-flex justify-content-between'>
                                    <label className='lab' htmlFor="amabilite">Amabilité</label>
                                    <div className='d-flex align-items-center'>
                                        <ReactStars
                                            key={`stars_${ratings.amabilite}`}
                                            value={Math.max(0, ratings.amabilite)}
                                            count={5}
                                            onChange={(value) => handleRatingChange('amabilite', value)}
                                            size={24}
                                            isHalf={true}
                                            emptyIcon={<i className="far fa-star"></i>}
                                            halfIcon={<i className="fa fa-star-half-alt"></i>}
                                            fullIcon={<i className="fa fa-star"></i>}
                                            activeColor="#ffd700"
                                            classNames="ratingAmabilite"
                                        />
                                        <span className='spa'> {ratings.amabilite ? `${ratings.amabilite}/5` : '0/5'}</span>
                                    </div>
                                </div>
                            </div>

                            {/* Section Communication */}
                            <div className="form-group">
                                <div className='d-flex justify-content-between'>
                                    <label className='lab' htmlFor="communication">Communication</label>
                                    <div className='d-flex align-items-center'>
                                        <ReactStars
                                            key={`stars_${ratings.communication}`}
                                            value={Math.max(0, ratings.communication)}
                                            count={5}
                                            onChange={(value) => handleRatingChange('communication', value)}
                                            size={24}
                                            isHalf={true}
                                            emptyIcon={<i className="far fa-star"></i>}
                                            halfIcon={<i className="fa fa-star-half-alt"></i>}
                                            fullIcon={<i className="fa fa-star"></i>}
                                            activeColor="#ffd700"
                                            classNames="ratingCommunication"
                                        />
                                        <span className='spa'> {ratings.communication ? `${ratings.communication}/5` : '0/5'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>                        

                        <input type="hidden" name="userId" value={user.idUser} />
                        <div className="form-group form">
                            <label className='lab-comment mb-2' htmlFor="comment">Facultatif</label>
                            <textarea className="form-control text-comment" rows="3" placeholder="Votre opinion compte pour nous..." value={comment} onChange={handleCommentChange}></textarea>
                        </div>
                        <button type="submit" className="fs-19 col-md-10 btn btn-primary submit px-3 mb-3">Soumettre</button>
                    </form>
                </div>                
            </div>

            {/* Modal */}
            <Modal show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>                    
                </Modal.Header>
                <Modal.Body>
                    <div className='col-md-8 offset-md-2'>
                        <p className='msg-notif'>Êtes-vous prêt à soumettre votre évaluation ?</p>
                    </div>                    
                </Modal.Body>
                <Modal.Footer>
                    <div className='d-flex justify-content-evenly pb-3 col-md-12'>
                        <Button className="col-md-4 btn btn-secondary pt-1 pb-1" style={{ borderRadius: '10px'}} variant="secondary" onClick={handleModalClose}>
                            Annuler
                        </Button>
                        <Button className="col-md-4 btn btn-primary pt-1 pb-1 mt-0" style={{ borderRadius: '10px'}}variant="primary" onClick={handleOKButtonClick}>
                            Oui
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>

        </section>

    );
}

export default Profile;
