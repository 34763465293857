import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../style/Dashboard/Compte.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfAlt, faPencil, faEye, faTrashAlt, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Menu from './Menu';
import { useParams, useNavigate, Link } from 'react-router-dom';
import moment from 'moment';
import useSession from '../useSession';


function Search() {
    const [users, setUsers] = useState([]);
    const [ratgeneral, setRatgeneral] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage] = useState(5);
    const [isHalf, setIsHalf] = useState(false); 
    const { id } = useParams();
    const navigate = useNavigate(); 
    const [showUsers, setShowUsers] = useState(false);

    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [results, setResults] = useState([]);

    /***** Start Vérification de la Session *****/ 
    const sessionData = useSession();
    if (sessionData) {
        console.log('Utilisation des données de session:', sessionData.email);       
        // axios.get(`https://apprating.dev0app.com/profile/${sessionData.email}`)
        //     .then(res => {                
        //         setUser(res.data[0]);                                   
        //     })
        //   .catch(error => console.error('Erreur lors de la récupération des informations de l\'utilisateur:', error));
    }
    else {
        console.log('Aucune session active');   
        navigate('/');           
    }
    /***** End Vérification de la Session *****/ 
   
    // const handleSearch = async (e) => {
    //    e.preventDefault();
    //    try {
    //      const response = await axios.post('https://apprating.dev0app.com/search', { startTime, endTime });
    //      setResults(response.data);
    //      setShowUsers(true); 


    //    } catch (error) {
    //      console.error('Erreur lors de la recherche:', error);
    //    }
    // };

    // const handleSearch = async (e) => {
    //     e.preventDefault();
    //     try {
    //       const response = await axios.post('https://apprating.dev0app.com/search', { startTime, endTime });
          
    //       const res = await axios.post('https://apprating.dev0app.com/generalRatingPeriode', { startTime, endTime });          
    //       setRatgeneral(res.data);
    //       setResults(response.data);
    //       setShowUsers(true); 
    //     } catch (error) {
    //       console.error('Erreur lors de la recherche:', error);
    //     }
    // };

    const handleSearch = async (e) => {
        e.preventDefault();
        try {
            // Effectuer les deux appels en parallèle
            const [searchResponse, generalRatingResponse] = await Promise.all([
                axios.post('https://apprating.dev0app.com/search', { startTime, endTime }),
                axios.post('https://apprating.dev0app.com/generalRatingPeriode', { startTime, endTime })
            ]);
                
            // Par exemple, fusionner les données dans un seul tableau
            // const combinedResults = [...searchResponse.data, ...generalRatingResponse.data];

            setResults(searchResponse.data);
            setShowUsers(true);
            
            setRatgeneral(generalRatingResponse.data);            

        } catch (error) {
            console.error('Erreur lors de la recherche:', error);
        }
    };

    
   
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = results.slice(indexOfFirstUser, indexOfLastUser);

    const totalPageCount = Math.ceil(results.length / usersPerPage);
    const paginate = pageNumber => setCurrentPage(pageNumber);

    function roundRating(num) {
        if (num % 1 === 0.5) {
           return num; // Garde la valeur avec 0.5
        }
        return Math.round(num); // Arrondit selon la règle habituelle
    }
   
    const handleUpdate = (id) => {
        console.log(id);
        axios.put(`https://apprating.dev0app.com/change_status/${id}`)        
            .then(response => {
                // Handle success, maybe update state or display a message
                console.log('User status updated successfully');                
            })
            .catch(error => {
                // Handle error
                console.error('Error updating user status:', error);
            });
    };

    
   
       
  return (   
    <section style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap'}}>
        <Menu />
        <div className="container">
            <div className='d-flex justify-content-center mb-4'>  
                <div className='col-md-12 offset-md-0'>               
                    <form onSubmit={handleSearch} className='searchForm align-items-start'>                    
                        <div className='col-md-8 inline' style={{ gap:'3rem' }}>
                            <label className='col-md-6'>
                                Début:
                                <input type="date" className='form-control pt-1 pb-1' value={startTime} onChange={e => setStartTime(e.target.value)} />
                            </label>
                            <label className='col-md-6'>
                                Fin:
                                <input type="date" className='form-control pt-1 pb-1' value={endTime} onChange={e => setEndTime(e.target.value)} />
                            </label>
                        </div>
                        <div className='col-md-4 offset-md-2'>
                            <button type="submit" className='col-md-8 btn btn-primary py-1 px-4'>Rechercher</button>
                        </div>                   
                    </form> 
                </div>     
            </div>
            {showUsers && (<div className="row">
                <div className='ratings_general col-md-6 text-center mb-5'>
                      <div className="container">
                            {ratgeneral.length > 0 ? (
                                <div>
                                {ratgeneral.map((item, index) => ( 
                                    <>
                                        <span className="rating_note" style={{ fontSize: '70px', fontFamily: 'Poppins', fontWeight: '600', color: '#000000'}}>{item.TotalRatingGeneral.toFixed(1).replace('.', ',')}</span>
                                        <div className='rat'>
                                            <div className='stars'>
                                                {[...Array(5)].map((star, index) => {
                                                    const ratingValue = index + 1;
                                                    const isHalfStar = isHalf && ratingValue === Math.ceil(item.TotalRatingGeneral); 
                                                    return (
                                                        <label key={ratingValue}>
                                                            <input type="radio" name="ratingAmabilite" value={ratingValue} style={{ display: 'none' }} disabled  />
                                                            <FontAwesomeIcon
                                                                icon={faStar}
                                                                style={{
                                                                cursor: 'pointer',
                                                                color: ratingValue <= (item.TotalRatingGeneral) ? '#FFE249' : '#DEDEDE', 
                                                                }}
                                                            />
                                                            {isHalfStar && <FontAwesomeIcon icon={faStar} style={{ position: 'absolute', left: 0, clipPath: 'inset(0 50% 0 0)', color: '#FFE249' }} />}
                                                        </label>
                                                    );
                                                })}    
                                            </div>
                                            <span className='rating_num fs-19'> {item.TotalRatingGeneral.toFixed(0) ? `${item.TotalRatingGeneral.toFixed(0)}/5` : '0/5'}</span>
                                        </div>
                                        <h2 className='lab_note' style={{ fontSize: '20px'}}>Note générale pour la période sélectionnée</h2>
                                    </> 
                                ))}
                                </div>
                            ) : (
                                <p></p>
                            )}  
                      </div>
                </div>
                <div className="users col-md-12 offset-md-0">
                    <div className="main-box clearfix">
                        <div className="table-responsive">
                            <table className="table user-list">
                                <thead>
                                    <tr>
                                        <th><span>Utilisateurs</span></th>
                                        <th><span>Date</span></th>    
                                        <th className='text-center'><span>Bloc</span></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {currentUsers.map(user => (  
                                    <tr key={user.idUser}>
                                        <td>
                                            <img src={`https://apprating.dev0app.com/uploads/${user.profile_picture}`}  className="rounded-circle" alt="Logo" style={{ maxWidth: '65px', margin: '0px 10px 0px 0px', borderWidth: "2px", borderStyle: 'solid', borderColor: roundRating(user.TotalRating) > 3 ? '#10CA00' : (roundRating(user.TotalRating) < 3 ? '#FF2A2A' : '#FF9900') }} />
                                            <Link to={`/dashboard/userDetail/${user.idUser}`} className="user-name">{user.name}</Link>    
                                            <span className="user-subhead">{user.role}</span>
                                            <div className='user-stars'>
                                                {[...Array(5)].map((star, index) => {
                                                    const ratingValue = index + 1;
                                                    const isFull = ratingValue <= roundRating(user.TotalRating);
                                                    const isHalf = ratingValue - 1 < roundRating(user.TotalRating) && ratingValue > roundRating(user.TotalRating);
                                                    const isEmpty = ratingValue > roundRating(user.TotalRating);

                                                    return (
                                                    <label key={ratingValue}>
                                                        <input type="radio" name="rating" value={ratingValue} style={{ display: 'none' }} disabled />
                                                        {isFull && <FontAwesomeIcon icon={faStar} style={{ cursor: 'pointer', color: '#FFE249' }} />}
                                                        {isHalf && <FontAwesomeIcon icon={faStarHalfAlt} style={{ cursor: 'pointer', color: '#FFE249' }} />}
                                                        {isEmpty && <FontAwesomeIcon icon={faStar} style={{ cursor: 'pointer', color: '#D9D9D9' }} />}
                                                    </label>
                                                    );
                                                })}
                                                <span className='rating_num fs-14'> {roundRating(user.TotalRating) ? `${roundRating(user.TotalRating)}/5` : '0/5'}</span>
                                            </div>
                                        </td>  
                                        <td>{moment(user.creation_date).format('YYYY-MM-DD HH:mm:ss')}</td>                                                                 
                                        <td className='text-center'>
                                            {user.bloc_id !== null ? (
                                                <span>N°<b>{user.bloc_id}</b></span>
                                            ) : null }                                             
                                        </td>
                                    </tr>   
                                ))}                                                 
                                </tbody>
                            </table>
                        </div> 
                        <div className="pagination-container">
                            <ul className="pagination">
                                <li><a href="#" onClick={() => paginate(currentPage - 1)}><FontAwesomeIcon icon={faChevronLeft} /></a></li>
                                {Array.from({length: totalPageCount}, (_, index) => (
                                    <li key={index} className={currentPage === index + 1 ? 'active' : ''}>
                                        <a href="#" onClick={() => paginate(index + 1)}>{index + 1}</a>
                                    </li>
                                ))}
                                <li><a href="#" onClick={() => paginate(currentPage + 1)}><FontAwesomeIcon icon={faChevronRight} /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            )}
        </div>
    </section>    
  )
}

export default Search