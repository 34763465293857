import React from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import './style/Remercier.css';
import './style/style.css';
import { Link } from 'react-router-dom';


function Remercier() {
    return (        
        <section className='d-flex text-center justify-content-center align-items-center' style={{ height: '100vh', justifyContent: 'flex-start', backgroundImage: 'url("bg-remercier.png")', backgroundSize: '100%', backgroundPosition: 'center bottom', backgroundRepeat: 'no-repeat' }}>
            <div className='container d-flex justify-content-flex-start flex-column' style={{ height: '100%'}}>
                <div className='row justify-content-center'>
                    <div className='col-md-6 text-center mb-5'>
                        <Link to="/profile"><img src="logo_bi.png" alt="Logo" style={{ maxWidth: '250px' }} /></Link>
                    </div>
                </div>
    
                <div className='row justify-content-center'>
                    <span className='icon4' style={{ position: 'relative', zIndex: 2 }}> 
                        <img src="face-grin-stars.png" alt="face grin starts" style={{ maxWidth: '60px' }} />                                
                    </span>
                    <div className='col-md-10'>
                        <div className='thanks-message'>
                            <span className='mb-5 text-center'>Merci d'avoir partagé votre expérience gastronomique avec nous !</span>
                        </div>  
                    </div>
                </div>
                
            </div>
            
        </section>
      )
}

export default Remercier