import React, {useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.css';
import Menu from './Menu';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import useSession from '../useSession';


function CreateAccount() {
    const [name, setName] = useState('');
    const [role, setRole] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [profilePicture, setProfilePicture] = useState('');    
    const [gender, setGender] = useState('Femme');    
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    
    function generatePassword() {
        const length = 8; 
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"; // Character set
        let newPassword = "";
        for (let i = 0; i < length; i++) {
            newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
        }
        return newPassword;
    }

    /***** Start Vérification de la Session *****/ 
    const sessionData = useSession();
    if (sessionData) {
        console.log('Utilisation des données de session:', sessionData.email);       
        // axios.get(`https://apprating.dev0app.com/profile/${sessionData.email}`)
        //     .then(res => {                
        //         setUser(res.data[0]);                                   
        //     })
        //   .catch(error => console.error('Erreur lors de la récupération des informations de l\'utilisateur:', error));
    }
    else {
        console.log('Aucune session active');   
        navigate('/');           
    }
    /***** End Vérification de la Session *****/ 

    function handleSubmit(event) {
        event.preventDefault();
      
        const formData = new FormData();
        formData.append('name', name);
        formData.append('email', email);
        formData.append('password', password);
        formData.append('profile_picture', profilePicture);
        formData.append('gender', gender);
              
        axios.post('https://apprating.dev0app.com/AddUser', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(response => {
          console.log(response.data);
          navigate('/dashboard/compte');
        })
        .catch(error => {
          console.error(error);
        });
    }

    // Function to handle password generation
    function handleGeneratePassword() {
        const newPassword = generatePassword();
        setPassword(newPassword);
    }

    
    // Function to toggle showing/hiding the password
    function togglePasswordVisibility() {
        setShowPassword(!showPassword);
    }
    
  return (    
    <section style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap'}}>
    <Menu />
        <div className="container mt-4">            
            <div className='row justify-content-center'>
                <div className='col-md-8 text-center mb-2'>
                    <h2 className='heading-text'>Créer un Compte</h2>
                    <h3 className='heading-detail mb-4'>Créer un nouveau compte pour serveur</h3>
                </div>                
            </div>
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <div className="main-box clearfix">
                        <form action='#' className='signin-form' onSubmit={handleSubmit}>
                            <div className='form-group mb-3'>
                                <input type="text" className='form-control fs-19' placeholder='Nom' required style={{ margin: 'auto', display: 'block' }}  onChange={e => setName(e.target.value)} />
                            </div>
                            <div className='form-group mb-3'>
                                <input type="file" className='form-control fs-19' placeholder='choisir image' required style={{ margin: 'auto', display: 'block' }} onChange={(e) => setProfilePicture(e.target.files[0])} />
                            </div>   
                            <div className='form-group mb-3'>
                                <input type="email" className='form-control fs-19' placeholder='Email' required style={{ margin: 'auto', display: 'block' }}  onChange={e => setEmail(e.target.value)} />
                            </div>  
                            <div className='form-group mb-3'>
                                <div className="input-group">
                                    <input type={showPassword ? "text" : "password"} className='form-control fs-19' placeholder='Mot de passe' required style={{ margin: 'auto', display: 'block' }} value={password} onChange={e => setPassword(e.target.value)} />
                                    <div className="input-group-append">
                                        <button className="btn btn-eye" type="button" onClick={togglePasswordVisibility}>
                                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                        </button>
                                    </div>
                                </div>
                                <div className='col-md-12 text-right mb-2'>
                                    <button type="button" className="btn btn-wb" onClick={handleGeneratePassword}>Générer un mot de passe</button>
                                </div>
                            </div>                           
                            <div className='form-group mb-3' onChange={e => setGender(e.target.value)}>
                                <select className="form-control fs-19">
                                    <option value="Femme">Femme</option>
                                    <option value="Homme">Homme</option>
                                </select>
                            </div>                                                                          
                            <div className='form-group row'>
                                <button type="submit" className='col-md-12 btn btn-primary submit px-3 mb-3' style={{ margin: 'auto', display: 'block' }}>Enregistrer</button>
                                <Link to="/dashboard/compte" className='col-md-12 btn btn-secondary submit px-3 d-none d-sm-inline-block' style={{ margin: 'auto', display: 'block' }}>Annuler</Link>
                            </div>                            
                        </form>   
                    </div>
                </div>
            </div>            
        </div>
    </section> 
  )
}

export default CreateAccount;