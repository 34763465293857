import React, {useState , useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import './style/Login.css';
import './style/style.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState(''); 
    const [errorMessage, setErrorMessage] = useState('');
    const navigate = useNavigate();
    const [rememberMe, setRememberMe] = useState(false);
    const [isRemembered, setIsRemembered] = useState(false);
    const [loginStatus, setLoginStatus] = useState('');

    axios.defaults.withCredentials = true;
    
    // useEffect(() => {
    //     axios.get('https://apprating.dev0app.com/protected-route')
    //     .then(res => {
    //         if(res.data.loggedIn == true){
    //             setLoginStatus(res.data.user);
    //             const role = res.data.user.role;
    //             if(role === "Serveur") {
    //                 navigate('/profile');
    //             }
    //             if(role === "Admin") {
    //                 navigate('/dashboard');
    //             } 
    //         }
    //         else {
    //             navigate('/');
    //             console.log(res);
    //         }            
    //     })
    //     .catch(error => {
    //         console.error("Erreur lors de la vérification de la session :", error);
    //         // Rediriger vers une page d'erreur ou de connexion
    //         navigate('/');
    //     });
    // }, []);  
        
    const handleSubmit = (e) => {
        e.preventDefault();
        console.log('Email:', email, 'Password:', password, 'Remember Me:', rememberMe);
        
        axios.post('https://apprating.dev0app.com/login', { email, password }) 
        .then(res => {            
            if (res.data.message === "Login Successfull") {                
                const role = res.data.user.role; 
                                
                const expirationDate = new Date();
                // Si rememberMe est coché, l'expiration est de 24h, sinon de 3h
                if (rememberMe) {
                    expirationDate.setHours(expirationDate.getHours() + 24); // Expire dans 24 heures
                } else {
                    expirationDate.setHours(expirationDate.getHours() + 3); // Expire dans 3 heures
                }
                  
                const sessionData = {
                email,
                rememberMe,
                expiresAt: expirationDate.toISOString(),
                };
                sessionStorage.setItem('sessionData', JSON.stringify(sessionData));    


                if(role === "Serveur") {
                    navigate('/profile');
                }
                if(role === "Admin") {
                    navigate('/dashboard');
                }                
            } else {
                setErrorMessage(res.data.message);
            }          
        })        
        .catch(err => console.log(err));
    }

    useEffect(() => {
        const sessionData = JSON.parse(sessionStorage.getItem('sessionData'));
        if (sessionData && new Date() < new Date(sessionData.expiresAt)) {
          // Les données sont encore valides, vous pouvez les utiliser
          console.log('Données valides:', sessionData);
        } else {
          // Les données ont expiré, supprimez-les
          sessionStorage.removeItem('sessionData');
          console.log('Données expirées');
        }
    }, []);
   
  return (
    <section className='section' style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <div className='container' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
            <div className='row justify-content-center'>
                <div className='col-md-10 text-center mb-2'>
                    <img src="logo_bi.png" alt="Logo" style={{ maxWidth: '250px' }} /> 
                    <h2 className='heading-text'>Connectez-vous</h2>
                    <h3 className='heading-detail mb-5'>Accédez à votre compte en vous connectant</h3>
                </div>  
            </div>
            <div className="row">
                <div className="col-md-6 offset-md-3">            
                    {/* Afficher le message d'erreur */}
                    {errorMessage && <h3 className='mb-2 text-center' style={{ fontWeight:400 ,color:'red'}}>{errorMessage}</h3>}
                    <div className='p-0'>
                        <form action='#' className='signin-form' onSubmit={handleSubmit}>
                            <div className='form-group mb-3'>
                                <input type="email" className='form-control' placeholder='Compte' required style={{ margin: 'auto', display: 'block' }}  value={email} onChange={e => setEmail(e.target.value)}  />
                            </div>
                            <div className='form-group mb-3'>
                                <input id='password-field' type="password" className='form-control' placeholder="Mot de passe" required style={{ margin: 'auto', display: 'block' }} value={password} onChange={e => setPassword(e.target.value)} />
                            </div>                       
                            <div className='form-group mb-3 d-flex justify-content-between' >
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                    <label className='checkbox-wrap checkbox-primary' style={{ width: 'auto', marginRight: '20px' }}>
                                        <input type="checkbox" checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} className='form-check-input' />
                                        Se souvenir de moi
                                    </label>
                                    <a href='#' className='txt-pwdf' style={{ width: 'auto' }}>Mot de passe oublié?</a>
                                </div>
                            </div>
                            <div className='form-group'>
                                <button type="submit" className='col-md-12 fs-22 btn btn-primary submit px-3 mb-3' style={{ margin: 'auto', display: 'block' }}>Se Connecter</button>
                            </div>                                                       
                        </form>                        
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Login