import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import Login from './Login';
import WebFont from 'webfontloader';
import React from 'react';
import Profile from './Profile';
import Validation from './Validation';
import CreateAccount from './Dashboard/CreateAccount';
import Home from './Dashboard/Home';
import Compte from  './Dashboard/Compte';
import Search from './Dashboard/Search';
import Remercier from './Remercier';
import UserDetail from './Dashboard/UserDetail';
import UserEdit from './Dashboard/UserEdit';
import Blocs from './Dashboard/Blocs';



function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/validation" element={<Validation />} />
        <Route exact path="/dashboard" element={<Home />} /> 
        <Route path="/dashboard/compte" element={<Compte />} />
        <Route path="/dashboard/Search" element={<Search />} />
        <Route path="/dashboard/CreateAccount" element={<CreateAccount />} />      
        <Route path="/dashboard/userDetail/:id" element={<UserDetail />} />
        <Route path="/dashboard/userEdit/:id" element={<UserEdit />} />
        <Route path="/dashboard/Blocs" element={<Blocs />} />
        <Route path="/remercier" element={<Remercier />} />        
      </Routes>
    </Router>
  );
}

export default App;
