import React, { useState, useEffect, useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { CiSearch } from "react-icons/ci";
import '../style/Menu.css';
// import SearchModal from './SearchModal';
import axios from "axios";
import { IoClose, IoMenu } from "react-icons/io5";
import useSession from "../useSession";


function Menu() {
    const navRef = useRef();
	// const [showModal, setShowModal] = useState(false);
    // const [searchResults, setSearchResults] = useState([]); // Déclaration de l'état
	const navigate = useNavigate();
	const [user, setUser] = useState('');

	const [isOpen, setIsOpen] = useState(false);

	const toggleNavbar = () => {
	  setIsOpen(!isOpen);
	};
	
	const [isResponsive, setIsResponsive] = useState(false);

	const toggleResponsive = () => {
		setIsResponsive(!isResponsive);
	};

	/***** Start Vérification de la Session *****/ 
    const sessionData = useSession();
    if (sessionData) {
        // console.log('Utilisation des données de session:', sessionData.email);       
        axios.get(`https://apprating.dev0app.com/profile/${sessionData.email}`)
            .then(res => {                
                setUser(res.data[0]);                                   
            })
          .catch(error => console.error('Erreur lors de la récupération des informations de l\'utilisateur:', error));
    }
    else {
        console.log('Aucune session active');   
        navigate('/');           
    }
    /***** End Vérification de la Session *****/ 
    

    const showNavbar = () => {
        navRef.current.classList.toggle("responsive_nav");
    };

	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	
	const toggleDropdown = (event) => {
		event.stopPropagation(); // Ajoutez cette ligne
		setIsDropdownOpen(!isDropdownOpen);
	};

	const handleLogout = () => {
		// Supprimer les données de session
		sessionStorage.removeItem('sessionData');
		// Rediriger l'utilisateur vers la page de connexion ou la page d'accueil
		navigate('/');
	};

    // const handleShowModal = () => setShowModal(true);
    // const handleCloseModal = () => setShowModal(false);

	// const handleSearch = async (startTime, endTime) => {
	// 	try {
	// 	const response = await axios.post('https://apprating.dev0app.com/search', { startTime, endTime });
	// 	console.log(response.data);
	// 	// Ici, vous pouvez afficher les résultats dans votre application.
	// 	setSearchResults(response.data);
	// 	} catch (error) {
	// 	console.error(error);
	// 	}
	// };

	return (			
		<header>	
			<div className={`topnav ${isResponsive ? 'responsive' : ''}`} id="myTopnav">
				<Link to="/dashboard">Tableau de bord</Link>
				<Link to="/dashboard/Blocs">Blocs</Link>
				<Link to="/dashboard/compte">Compte</Link>
				<Link to="/dashboard/Search">Filter</Link>	

				<ul className="menu-list ml-auto picture_link" style={{ display: 'block', listStyle: 'none'}}>					
					<li className="avatar-container" onClick={toggleDropdown}>
					<img src={`https://apprating.dev0app.com/uploads/${user.profile_picture}`} className="rounded-circle" alt={`${user.name}'s profile picture`} style={{ maxWidth: '50px', margin: '3px auto 3px' }} />
						{isDropdownOpen && (
							<ul className="dropdown-menu" style={{ display: 'block'}}>								
								<li><button onClick={handleLogout} variant="contained" className="mb-0 ml-0 mr-0">Déconnexion</button></li>
							</ul>
						)}
					</li>
				</ul>	
				<a href="javascript:void(0);" className="icon" onClick={toggleResponsive}>
					{isResponsive ? <IoClose /> : <IoMenu />}
				</a>
			</div>			
			{/* <nav className="navbar">
				<div className="navbar-container">
					<ul className={`navbar-links ${isOpen ? 'active' : ''}`}>
						<li className="navbar-item"><Link to="/dashboard" className="navbar-link">Dashboard</Link></li>
						<li className="navbar-item"><Link to="/dashboard/Blocs" className="navbar-link">Blocs</Link></li>
						<li className="navbar-item"><Link to="/dashboard/compte" className="navbar-link">Compte</Link></li>
						<li className="navbar-item"><Link to="/dashboard/Search" className="navbar-link">Filter</Link></li>
						<li className="navbar-item"><CiSearch /></li>		
					</ul>
					<button className="navbar-toggle" onClick={toggleNavbar}>
						<span className="navbar-icon">&#9776;</span>
					</button>
					<ul className="menu-list ml-auto">					
						<li className="avatar-container" onClick={toggleDropdown}>
						<img src={`https://apprating.dev0app.com/uploads/${loginStatus.profile_picture}`} className="rounded-circle" alt={`${loginStatus.name}'s profile picture`} style={{ maxWidth: '50px', margin: '3px auto 3px' }} />
							{isDropdownOpen && (
								<ul className="dropdown-menu" style={{ display: 'block'}}>								
									<li><Link to="/logout" className="mb-0 ml-0 mr-0">Déconnexion</Link></li>
								</ul>
							)}
						</li>
					</ul>						
				</div>
			</nav>	 */}
		</header>
	);
}

export default Menu;