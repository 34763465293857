import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

// Hook personnalisé pour gérer la session
// function useSession() {

//  useEffect(() => {
//     const sessionData = JSON.parse(sessionStorage.getItem('sessionData'));
//     if (sessionData && new Date() < new Date(sessionData.expiresAt)) {
//       // Les données sont encore valides, vous pouvez les utiliser
//       console.log('Données valides:', sessionData);
//       // Vous pouvez retourner les données de session ici si nécessaire
//       return sessionData;
//     } else {
//       // Les données ont expiré, supprimez-les
//       sessionStorage.removeItem('sessionData');
//       console.log('Données expirées');
//       // Retournez null ou une valeur par défaut si les données de session ne sont pas valides
//       return null;
//     }
//  }, []);
// }

// Hook personnalisé pour gérer la session
function useSession() {
    // Utilisez useState pour créer un état local pour les données de session
    const [sessionData, setSessionData] = useState(null);
    const navigate = useNavigate();
   
    useEffect(() => {
       const storedSessionData = JSON.parse(sessionStorage.getItem('sessionData'));
       if (storedSessionData && new Date() < new Date(storedSessionData.expiresAt)) {
         // Les données sont encore valides, vous pouvez les utiliser
         console.log('Données valides:', storedSessionData);
         // Mettez à jour l'état local avec les données de session
         setSessionData(storedSessionData);
       } else {
         // Les données ont expiré, supprimez-les
         sessionStorage.removeItem('sessionData');
         console.log('Données expirées');
         // Mettez à jour l'état local avec null ou une valeur par défaut
         setSessionData(null);
         // Redirigez l'utilisateur vers la page de connexion
         navigate('/'); 
       }
    }, [navigate]); // Assurez-vous que le tableau de dépendances est vide pour exécuter l'effet une seule fois
   
    // Retournez les données de session à partir de votre hook personnalisé
    return sessionData;
}

export default useSession;

