import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfAlt, faPhone } from '@fortawesome/free-solid-svg-icons';
import Menu from './Menu';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import useSession from '../useSession';


const UserDetail = () => {
    const { id } = useParams();
    const [combinedUsers, setCombinedUsers] = useState([]);
    const navigate = useNavigate(); 
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    
    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        console.log(dates);
    };
    
    /***** Start Vérification de la Session *****/ 
    const sessionData = useSession();
    if (sessionData) {
        console.log('Utilisation des données de session:', sessionData.email);       
        // axios.get(`https://apprating.dev0app.com/profile/${sessionData.email}`)
        //     .then(res => {                
        //         setUser(res.data[0]);                                   
        //     })
        //   .catch(error => console.error('Erreur lors de la récupération des informations de l\'utilisateur:', error));
    }
    else {
        console.log('Aucune session active');   
        navigate('/');           
    }
    /***** End Vérification de la Session *****/ 

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userRatingsResponse = await axios.get(`https://apprating.dev0app.com/users_rating/${id}`);
                const userRatings = userRatingsResponse.data;

                const combinedUsersMap = userRatings.reduce((acc, userRating) => {
                    const { id, name, username, profile_picture, Sexe, role, email, password, telephone } = userRating;
                    if (!acc[id]) {
                        acc[id] = { id, name, username, profile_picture, Sexe, role, email, password, telephone, ratings: [] };
                    }
                    acc[id].ratings.push({
                        rating_id: userRating.rating_id,
                        gout_rating: userRating.gout_rating,
                        amabilite_rating: userRating.amabilite_rating,
                        communication_rating: userRating.communication_rating,
                        commentaire: userRating.commentaire,
                        tel: userRating.telephone,
                        creation_date: userRating.creation_date
                    });
                    return acc;
                }, {});

                const combinedUsersArray = Object.values(combinedUsersMap);

                // Calculate the total rating for each user
                combinedUsersArray.forEach(user => {
                    const totalRating = (user.ratings.reduce((acc, rating) => acc + rating.gout_rating, 0) / user.ratings.length +
                        user.ratings.reduce((acc, rating) => acc + rating.amabilite_rating, 0) / user.ratings.length +
                        user.ratings.reduce((acc, rating) => acc + rating.communication_rating, 0) / user.ratings.length) / 3;
                    user.totalRating = totalRating;
                });
               
                setCombinedUsers(combinedUsersArray);
            } catch (error) {
                console.error('Error fetching user ratings:', error);
            }
        };

        fetchData();
    }, []);
        
    const filteredRatings = combinedUsers.map(user => ({
        ...user,
        ratings: user.ratings.filter(rating => {
          const ratingDate = moment(rating.creation_date);
          return ratingDate.isSameOrAfter(startDate) && ratingDate.isSameOrBefore(endDate);
        })
    }));
    console.log(filteredRatings);

    function roundRating(num) {
        // Vérifie si la partie décimale est exactement 0.5
        if (num % 1 === 0.5) {
        return num; // Garde la valeur avec 0.5
        }
        return Math.round(num); // Arrondit selon la règle habituelle
    }
    //
    const moyenneRating = (rating) => {
        return (rating.gout_rating + rating.amabilite_rating + rating.communication_rating) / 3;
    };
    
    //
    const [selectedRating, setSelectedRating] = useState(null);

    const handleRatingClick = (rating) => {
        setSelectedRating(rating);
    };

    const handleCloseModal = () => {
        setSelectedRating(null);
    };
  
 
  return (
    <section style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap'}}>
        <Menu />
        <div className="container-fluid">
            <div className="row">
            
                <div className="col-md-8 list-ratings" style={{ width: '64%', paddingLeft: '90px'}}>
                    {combinedUsers.map(user => (
                        <div className='user col-md-12'>
                            <div className='row'>
                                <div className='col-md-7 rating_user'>                                
                                    <div className='detail_user' key={user.idUser} style={{ paddingLeft: '0px'}}>                              
                                        <img src={`https://apprating.dev0app.com/uploads/${user.profile_picture}`}  className="rounded-circle" alt="Logo" style={{ maxWidth: '65px', margin: '0px 10px 0px 0px', borderWidth: "2px", borderStyle: 'solid', borderColor: roundRating(user.totalRating) > 3 ? '#10CA00' : (roundRating(user.totalRating) < 3 ? '#FF2A2A' : '#FF9900') }} />
                                        <div>
                                            <p className='username'>{user.name}</p>
                                            <div className='t2'>
                                                {[...Array(5)].map((star, index) => {
                                                    const ratingValue = index + 1;
                                                    const isFull = ratingValue <= roundRating(user.totalRating);
                                                    const isHalf = ratingValue - 1 < roundRating(user.totalRating) && ratingValue > roundRating(user.totalRating);
                                                    const isEmpty = ratingValue > roundRating(user.totalRating);

                                                    return (
                                                    <label key={ratingValue}>
                                                        <input type="radio" name="rating" value={ratingValue} style={{ display: 'none' }} disabled />
                                                        {isFull && <FontAwesomeIcon icon={faStar} style={{ cursor: 'pointer', color: '#FFE249' }} />}
                                                        {isHalf && <FontAwesomeIcon icon={faStarHalfAlt} style={{ cursor: 'pointer', color: '#FFE249' }} />}
                                                        {isEmpty && <FontAwesomeIcon icon={faStar} style={{ cursor: 'pointer', color: '#D9D9D9' }} />}
                                                    </label>
                                                    );
                                                })}
                                                <span className='rating_num fs-14'> {roundRating(user.totalRating) ? `${roundRating(user.totalRating)}/5` : '0/5'}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-5 datePicker d-flex align-items-center'>                                
                                    <DatePicker                                        
                                        selected={startDate}
                                        onChange={handleDateChange}
                                        startDate={startDate}
                                        endDate={endDate}
                                        selectsRange
                                        className="text-datepicker"
                                        placeholderText="Sélectionnez une période"                                        
                                        isClearable
                                    />
                                </div>
                            </div>
                            
                            <div className="table-responsive">
                                <table className="table user-list">
                                    <tbody>                                                                                                  
                                        {user.ratings
                                            .filter(rating => {
                                                // Convertir les dates en objets Date pour la comparaison
                                                const creationDate = new Date(rating.creation_date);
                                                const start = startDate ? new Date(startDate) : new Date(0); // Date minimale possible
                                                const end = endDate ? new Date(endDate) : new Date(); // Date maximale possible
                                                return creationDate >= start && creationDate <= end;
                                            }).map((rating, index) => (
                                            <tr key={index}>
                                            <td><a href="#" onClick={() => handleRatingClick(rating)} style={{ fontWeight: '700'}}>#R00{rating.rating_id}</a></td>
                                            <td>
                                                <div className='stars_rating'>
                                                    {[...Array(5)].map((star, index) => {
                                                        const ratingValue = index + 1;
                                                        const isFull = ratingValue <= roundRating(moyenneRating(rating));
                                                        const isHalf = ratingValue - 1 < roundRating(moyenneRating(rating)) && ratingValue > roundRating(moyenneRating(rating));
                                                        const isEmpty = ratingValue > roundRating(moyenneRating(rating));

                                                        return (
                                                        <label key={ratingValue}>
                                                            <input type="radio" name="rating" value={ratingValue} style={{ display: 'none' }} disabled />
                                                            {isFull && <FontAwesomeIcon icon={faStar} style={{ cursor: 'pointer', color: '#FFE249' }} />}
                                                            {isHalf && <FontAwesomeIcon icon={faStarHalfAlt} style={{ cursor: 'pointer', color: '#FFE249' }} />}
                                                            {isEmpty && <FontAwesomeIcon icon={faStar} style={{ cursor: 'pointer', color: '#D9D9D9' }} />}
                                                        </label>
                                                        );
                                                    })}
                                                    <span className='fs-14 MoyRating'> {roundRating(moyenneRating(rating)) ? `${roundRating(moyenneRating(rating))}/5` : '0/5'}</span>
                                                </div>
                                            </td>                                                                  
                                            <td>{moment(rating.creation_date).format('YYYY-MM-DD HH:mm:ss')}</td>                                                                 
                                            <td>
                                                {rating.tel && (
                                                    <div className='tel mb-2 text-center'>
                                                        <FontAwesomeIcon icon={faPhone} style={{ cursor: 'pointer', color: '#C7393A' }} />
                                                    </div>
                                                )} 
                                            </td>        
                                        </tr>
                                        ))} 
                                        {user.ratings
                                            .filter(rating => {
                                                const creationDate = new Date(rating.creation_date);
                                                const start = startDate ? new Date(startDate) : new Date(0);
                                                const end = endDate ? new Date(endDate) : new Date();
                                                return creationDate >= start && creationDate <= end;
                                            }).length === 0 && (
                                            <tr>
                                                <td colSpan="3"><span className='textcenter'>Aucune notation trouvée pour la période sélectionnée.</span></td>
                                            </tr>
                                        )}                               
                                    </tbody> 
                                </table>
                                <Modal show={selectedRating !== null} onHide={handleCloseModal}>
                                    <Modal.Body>                                
                                    {selectedRating && (
                                        <>
                                        <div className='col-md-12 offset-md-0' style={{ padding: '1.5rem'}}>
                                            
                                            {selectedRating.tel && (
                                                <div className='tel mb-2 text-center'>
                                                    <a href={`tel:${selectedRating.tel}`}>{selectedRating.tel}</a>
                                                </div>
                                            )}                                     
                                            
                                            <div className=''>                                      
                                                <div className='ratings'>
                                                    <span className="label fs-22" htmlFor="qualite_gout">Qualité goût</span>                                                
                                                    <div className='ratdiv1'>                                                
                                                        {[...Array(5)].map((star, index) => {
                                                            const ratingValue = index + 1;
                                                            return ( 
                                                                <label>
                                                                    <input type="radio" name="qualite_gout" value={ratingValue} style={{ display: 'none' }}  disabled/>
                                                                    <FontAwesomeIcon icon={faStar} style={{ cursor: 'pointer', color: ratingValue <= selectedRating.gout_rating ? '#FFE249' : '#DBDBDB' }} />
                                                                </label>
                                                            );
                                                        })}                                                 
                                                        <span className='rating_num fs-19'> {selectedRating.gout_rating ? `${selectedRating.gout_rating}/5` : '0/5'}</span>
                                                    </div>
                                                </div>     
                                                <div className='ratings'>
                                                    <span className="label fs-22" htmlFor="amabilite">Amabilite</span>                                                
                                                    <div className='ratdiv1'>                                                
                                                        {[...Array(5)].map((star, index) => {
                                                            const ratingValue = index + 1;
                                                            return ( 
                                                                <label>
                                                                    <input type="radio" name="amabilite" value={ratingValue} style={{ display: 'none' }}  disabled/>
                                                                    <FontAwesomeIcon icon={faStar} style={{ cursor: 'pointer', color: ratingValue <= selectedRating.amabilite_rating ? '#FFE249' : '#DBDBDB' }} />
                                                                </label>
                                                            );
                                                        })}                                                 
                                                        <span className='rating_num fs-19'> {selectedRating.amabilite_rating ? `${selectedRating.amabilite_rating}/5` : '0/5'}</span>
                                                    </div>
                                                </div>
                                                <div className='ratings'>
                                                    <span className="label fs-22" htmlFor="communication">Communication</span>                                                
                                                    <div className='rating_div'>                                                
                                                        {[...Array(5)].map((star, index) => {
                                                            const ratingValue = index + 1;
                                                            return ( 
                                                                <label>
                                                                    <input type="radio" name="communication" value={ratingValue} style={{ display: 'none' }}  disabled/>
                                                                    <FontAwesomeIcon icon={faStar} style={{ cursor: 'pointer', color: ratingValue <= selectedRating.communication_rating ? '#FFE249' : '#DBDBDB' }} />
                                                                </label>
                                                            );
                                                        })}                                                 
                                                        <span className='rating_num fs-19'> {selectedRating.communication_rating ? `${selectedRating.communication_rating}/5` : '0/5'}</span>
                                                    </div>
                                                </div>                                              
                                            </div>
                                            
                                            <div className='comment'>
                                                <p>{selectedRating.commentaire}</p>
                                            </div>

                                        </div>                                    
                                        </>
                                    )}
                                    </Modal.Body>                              
                                </Modal>
                            </div>
                        </div>                 
                    ))}
                    <div className='form-group row'>
                        <button type="submit" className='col-md-8 btn btn-primary submit px-3 mb-3' style={{ margin: 'auto', display: 'none' }}>Afficher Plus</button>
                    </div> 
                </div>

                <div className="col-md-4 bg-vector" style={{ width: '36%', backgroundImage: 'url("/./././vector.png")',height: '100vh',backgroundSize: 'cover', backgroundPosition: 'center',  opacity: '0.3'}}>
                </div>
            </div>          
        </div>
    </section>  
  );
};

 
export default UserDetail;