import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../style/Dashboard/Home.css';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';
// import ReactStars from 'react-rating-stars-component';
import Menu from './Menu';
import useSession from '../useSession';


function Home() {
    const [users, setUsers] = useState([]);
    const navigate = useNavigate();
    const [ratings, setRatings] = useState([]);
    const [isHalf, setIsHalf] = useState(false); 
    const [user, setUser] = useState('');

    /***** Start Vérification de la Session *****/ 
    const sessionData = useSession();
    if (sessionData) {
        console.log('Utilisation des données de session:', sessionData.email);       
        // axios.get(`https://apprating.dev0app.com/profile/${sessionData.email}`)
        //     .then(res => {                
        //         setUser(res.data[0]);                                   
        //     })
        //   .catch(error => console.error('Erreur lors de la récupération des informations de l\'utilisateur:', error));
    }
    else {
        console.log('Aucune session active');   
        navigate('/');           
    }
    /***** End Vérification de la Session *****/ 

    // useEffect(() => {
    //     axios.get('https://apprating.dev0app.com/protected-route')
    //     .then(res => {
    //         if(res.data.loggedIn == true){
    //             setLoginStatus(res.data.user);
    //         }
    //         else {
    //             navigate('/');
    //             console.log(res);
    //         }             
    //     })
    //     .catch(error => {
    //         console.error("Erreur lors de la vérification de la session :", error);
    //         // Rediriger vers une page d'erreur ou de connexion
    //         navigate('/');
    //     });
    // }, []);  

    useEffect(() => {
        axios.get('https://apprating.dev0app.com/topUsers')
            .then(res => {
                setUsers(res.data);
                console.log(res.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }, []);

    useEffect(() => {
        axios.get('https://apprating.dev0app.com/generalRating')
            .then(res => {
                setRatings(res.data);
                // console.log(res.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }, []);

    let sum = 0;
    ratings.forEach((rating) => {
        Object.values(rating).forEach((value) => {
          sum += value;
        });
    });
    
    function roundRating(num) {
        // Vérifie si la partie décimale est exactement 0.5
        if (num % 1 === 0.5) {
           return num; // Garde la valeur avec 0.5
        }
        return Math.round(num); // Arrondit selon la règle habituelle
    }

    // useEffect(() => {
    //     // Vérifier si la session côté client est valide
    //     const sessionData = JSON.parse(sessionStorage.getItem('usession'));
    //     if (!sessionData) {
    //       // Si la session n'existe pas, redirigez vers la page d'accueil
    //       console.log("Session not found");
    //     } else {
    //         console.log("Session Sucess");
    //        const parsedSessionData = JSON.parse(sessionData);
    //        const currentTime = Date.now();
    //        if (currentTime > parsedSessionData.expiresAt) {
    //          // Si la session est expirée, redirigez vers la page d'accueil
    //          navigate('/');
    //        } else {
    //          // Si la session est valide, procédez à l'appel API
    //          axios.get('https://apprating.dev0app.com/dashboard')
    //            .then(res => {
    //              if (res.data.message === "Session Error") {
    //                console.log("Session Error");
    //                navigate('/');
    //              } else {
    //                console.log("Session");
    //                //setUser(res.data.user); // Vous pouvez décommenter cette ligne pour mettre à jour l'état de l'utilisateur
    //              }
    //            })
    //            .catch(error => {
    //              console.error('Error fetching dashboard:', error);
    //            });
    //        }
    //     }
    //    }, []);

    return (
        <section className='dashboard-section' style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className='justify-content-center'>  
                <Menu/>                      
                <div className='ratings_general col-md-6 text-center mb-5'>
                      <div className="container">
                            <span className='rating_note'>{(sum / 3).toFixed(1).replace('.', ',')}</span>
                            <div className='rat'>
                                <div className='stars'>
                                {[...Array(5)].map((star, index) => {
                                    const ratingValue = index + 1;
                                    const isHalfStar = isHalf && ratingValue === Math.ceil(sum / 3); 
                                    return (
                                        <label key={ratingValue}>
                                            <input type="radio" name="ratingAmabilite" value={ratingValue} style={{ display: 'none' }} disabled  />
                                            <FontAwesomeIcon
                                                icon={faStar}
                                                style={{
                                                cursor: 'pointer',
                                                color: ratingValue <= (sum / 3) ? '#FFE249' : '#DEDEDE', // If the star is less than or equal to the calculated rating, color it yellow, otherwise gray
                                                }}
                                            />
                                            {isHalfStar && <FontAwesomeIcon icon={faStar} style={{ position: 'absolute', left: 0, clipPath: 'inset(0 50% 0 0)', color: '#FFE249' }} />}
                                        </label>
                                    );
                                })}    
                                </div>
                                <span className='rating_num fs-19'> {(sum / 3).toFixed(0) ? `${(sum / 3).toFixed(0)}/5` : '0/5'}</span>
                            </div>
                            <h2 className='lab_note'>Note générale</h2>
                      </div>
                </div>
                <div>
                    <div className='ratings_general col-md-6 offset-md-3'>
                        {ratings.map((rating, index) => (
                            <div className='form-group'>
                                <div className='' key={index}>
                                    {Object.entries(rating).map(([key, value]) => (                                        
                                        <div className='ratings'>
                                            <span className="label fs-29" htmlFor={`rating${key}`} >{ (key == "Gout") ? "Qualité goût" : key}</span>                                                
                                            <div className='ratdiv1'>                                                
                                                {[...Array(5)].map((star, index) => {
                                                    const ratingValue = index + 1;
                                                    return ( 
                                                        <label key={key}>
                                                            <input type="radio" name={`rating${key}`}  value={ratingValue} style={{ display: 'none' }}  disabled/>
                                                            <FontAwesomeIcon icon={faStar} style={{ cursor: 'pointer', color: ratingValue <= Math.ceil(value) ? '#FFE249' : '#DBDBDB' }} />
                                                        </label>
                                                    );
                                                })}                                                 
                                                <span className='rating_num fs-19'> {Math.ceil(value) ? `${Math.ceil(value) }/5` : '0/5'}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                    <hr className='trait'/>
                    <div className="form-group form">                        
                        <div className='row users col-md-10 offset-md-1'>   
                        <h2 className='lab_rat'>Les dernières évaluations</h2>                         
                        {users.map(user => (
                            <div className='user d-flex justify-content-around col-md-6'>
                                <div className='detail_user' key={user.idUser}>                              
                                    <img src={`https://apprating.dev0app.com/uploads/${user.profile_picture}`}  className="rounded-circle" alt="Logo" style={{ maxWidth: '65px', margin: '0px 10px 0px 0px', borderWidth: "2px", borderStyle: 'solid', borderColor: roundRating(user.TotalRating) > 3 ? '#10CA00' : (roundRating(user.TotalRating) < 3 ? '#FF2A2A' : '#FF9900') }} />
                                    <div>
                                        <Link to={`/dashboard/userDetail/${user.idUser}`} ><p className='username'>{user.name}</p></Link>
                                        <div className='t2'>
                                            {[...Array(5)].map((star, index) => {
                                                const ratingValue = index + 1;
                                                const isFull = ratingValue <= roundRating(user.TotalRating);
                                                const isHalf = ratingValue - 1 < roundRating(user.TotalRating) && ratingValue > roundRating(user.TotalRating);
                                                const isEmpty = ratingValue > roundRating(user.TotalRating);

                                                return (
                                                <label key={ratingValue}>
                                                    <input type="radio" name="rating" value={ratingValue} style={{ display: 'none' }} disabled />
                                                    {isFull && <FontAwesomeIcon icon={faStar} style={{ cursor: 'pointer', color: '#FFE249' }} />}
                                                    {isHalf && <FontAwesomeIcon icon={faStarHalfAlt} style={{ cursor: 'pointer', color: '#FFE249' }} />}
                                                    {isEmpty && <FontAwesomeIcon icon={faStar} style={{ cursor: 'pointer', color: '#D9D9D9' }} />}
                                                </label>
                                                );
                                            })}
                                            <span className='rating_num fs-14'> {roundRating(user.TotalRating) ? `${roundRating(user.TotalRating)}/5` : '0/5'}</span>
                                        </div>                                        
                                    </div>
                                </div>
                            </div>
                        ))}                        
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Home;
