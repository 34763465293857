import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css'
import './style/Validation.css';
import './style/style.css';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import useSession from './useSession';

    // Composant d'entrée personnalisé
    const CustomInput = React.forwardRef(({ value, onChange }, ref) => (
    <input
       type="tel"
       value={value}
       onChange={onChange}
       ref={ref}
       className='telInput'
       placeholder='+212___ ___ ___'
       style={{ backgroundColor: '#EDEDED', border: '0', width: '100%' }}
       maxLength={15} // Limite la longueur à 9 chiffres
    />
   ));

function Validation() {
    const [user, setUser] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    const ratingID = location.state.ratingID;
    const [showModal, setShowModal] = useState(false);
    const [messageSent, setMessageSent] = useState(false);

    /***** Start Vérification de la Session *****/ 
    const sessionData = useSession();

    if (sessionData) {
        // console.log('Utilisation des données de session:', sessionData.email);       
        axios.get(`https://apprating.dev0app.com/profile/${sessionData.email}`)
            .then(res => {                
                setUser(res.data[0]);                                   
            })
          .catch(error => console.error('Erreur lors de la récupération des informations de l\'utilisateur:', error));
    }
    else {
        console.log('Aucune session active');   
        navigate('/');           
    }
    /***** End Vérification de la Session *****/ 

    // useEffect(() => {       
    //     axios.get('https://apprating.dev0app.com/profile')
    //         .then(res => {
    //             if(res.data.valid){
    //                 setUser(res.data.user);
    //             }
    //             else {
    //                 navigate('/login');
    //             }                
    //         })
    //         .catch(error => {
    //             console.error('Error fetching profile:', error);              
    //         });
    // }, []);

    const [tel, setTel] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault(); 
        setShowModal(true);
    };

    const handleButtonOK = () => {        
       
        setMessageSent(true); 
        if (messageSent) {
            
            axios.post('https://apprating.dev0app.com/addPhoneNumber', { ratingID, tel })
            .then(res => {
                if (res.data.message === "Success") {                    
                    console.log('Phone number added successfully');
                    navigate('/profile');     
                    setShowModal(false); 
                }
                else {
                    setShowModal(false); 
                    navigate('/profile');  
                }
            })    
            .catch(error => {
                console.error('Error submitting message:', error);
            });  
        }
    };

    const handleButtonClose = () => {
        setMessageSent(false);
        setShowModal(false);
        navigate('/profile');
    };

    const handlePhoneChange = (value) => {
        // Ensure the input value does not exceed 9 characters
        if (value.length <= 9) {
          setTel(value);
        }
    };


  return (
    <section className='section' style={{ textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <div className='container' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100%' }}>
            <div className='row justify-content-center'>
                <div className='col-md-8 text-center mb-5'>                    
                    <img src="logo_bi.png" alt="Logo" style={{ maxWidth: '250px' }} />
                    <h2 className='heading-text fs-33'>Veuillez nous laisser votre numéro de téléphone</h2>
                </div>
                <span style={{ display: 'none' }}>{user.name}</span>
            </div>

            <div className='row'>
                <div className='col-md-10 offset-md-1'>
                    <p className='text-validation text-center mb-7'>
                        Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,
                        when an unknown printer took  a galley of type and
                        scrambled it to make a type specimen book.
                    </p>
                </div>
                
                <div className='col-md-8 offset-md-2'>                    
                    <div className='p-0'>
                        <form action='#' onSubmit={handleSubmit}>
                            <div className='form-group mb-3 text-left'>
                                <label className='lab-tel fs-22' for="phoneNumber">Téléphone</label>
                                <PhoneInput
                                    defaultCountry="MA"                                    
                                    placeholder='+212___ ___ ___ ___ ___'
                                    value={tel}
                                    onChange={setTel}
                                    international
                                    withCountryCallingCode
                                    disableCountryCode={true}
                                    inputComponent={CustomInput} // Utilise le composant d'entrée personnalisé
                                />       
                                {/* (e) => setTel(e.target.value) */}
                            </div>
                            <div className='form-group row'>
                                <button type="submit" className='col-md-12 fs-22 btn btn-primary submit px-3 mb-3' style={{ margin: 'auto' }}>Envoyer</button>
                                <Link to="/Profile" className='col-md-12 fs-22 btn btn-secondary submit px-3' style={{ margin: 'auto' }}>Non, Merci</Link>
                            </div>                          
                        </form>                        
                    </div>
                </div>
            </div>
        </div>

        {/* Modal */}
        <Modal show={showModal} onHide={handleButtonClose}>
            <Modal.Header closeButton>                    
            </Modal.Header>
            <Modal.Body>
                <div className='col-md-8 offset-md-2'>
                    <p className='msg-notif'>Vous voulez enregistrer votre numéro de téléphone ?</p>
                </div>                    
            </Modal.Body>
            <Modal.Footer>
                <div className='d-flex justify-content-evenly pb-3 col-md-12'>
                    <Button className="col-md-4 btn btn-secondary pt-1 pb-1" style={{ borderRadius: '10px'}} variant="secondary" onClick={handleButtonClose}>
                        Annuler
                    </Button>
                    <Button className="col-md-4 btn btn-primary pt-1 pb-1 mt-0" style={{ borderRadius: '10px'}}variant="primary" onClick={handleButtonOK}>
                        Oui
                    </Button>                       
                </div>
            </Modal.Footer>
        </Modal>
    </section>

  )
}

export default Validation