import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../style/Dashboard/Compte.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfAlt, faPencil, faEye, faTrashAlt, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Menu from './Menu';
import { useParams, useNavigate, Link } from 'react-router-dom';
import useSession from '../useSession';


function Compte() {
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [usersPerPage] = useState(5);
    const [isHalf, setIsHalf] = useState(false); 
    const { id } = useParams();
    const navigate = useNavigate(); 

    /***** Start Vérification de la Session *****/ 
    const sessionData = useSession();
    if (sessionData) {
        console.log('Utilisation des données de session:', sessionData.email);       
        // axios.get(`https://apprating.dev0app.com/profile/${sessionData.email}`)
        //     .then(res => {                
        //         setUser(res.data[0]);                                   
        //     })
        //   .catch(error => console.error('Erreur lors de la récupération des informations de l\'utilisateur:', error));
    }
    else {
        console.log('Aucune session active');   
        navigate('/');           
    }
    /***** End Vérification de la Session *****/ 
        
    useEffect(() => {
        axios.get('https://apprating.dev0app.com/AllUsers')
            .then(res => {
                setUsers(res.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }, []);

    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

    const totalPageCount = Math.ceil(users.length / usersPerPage);
    const paginate = pageNumber => setCurrentPage(pageNumber);

    function roundRating(num) {
        if (num % 1 === 0.5) {
           return num; // Garde la valeur avec 0.5
        }
        return Math.round(num); // Arrondit selon la règle habituelle
    }

    // const handleDelete = (id) => {
    //     console.log(id);
    //     axios.delete(`https://apprating.dev0app.com/delete/${id}`)
    //     .then(res => {
    //         // navigate('/dashboard/compte');
    //         window.location.reload();
    //     })
    //     .catch(err => console.log(err));       
    // }

    const handleUpdate = (id) => {
        // console.log(id);
        axios.put(`https://apprating.dev0app.com/change_status/${id}`)        
            .then(response => {
                console.log('User status updated successfully');                
            })
            .catch(error => {
                // Handle error
                console.error('Error updating user status:', error);
            });
    };
       
  return (   
    <section style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap'}}>
        <Menu />
        <div className="container">
            <div className='d-flex justify-content-end mb-4'>                
                <Link to="/dashboard/CreateAccount" className='d-sm-inline-block btn btn-secondary px-3'>Ajouter un nouveau compte</Link>
            </div>
            <div className="row">
                <div className="users col-md-12 offset-md-0">
                    <div className="main-box clearfix">
                        <div className="table-responsive">
                            <table className="table user-list">
                                <thead>
                                    <tr>
                                        <th><span>Utilisateurs</span></th>
                                        <th className='email'><span>Email</span></th>    
                                        <th className='bloc text-center'><span>Bloc</span></th>                                    
                                        <th className='text-center'><span>Actions</span></th>
                                    </tr>
                                </thead>
                                <tbody>
                                {currentUsers.map(user => (  
                                    <tr key={user.idUser}>
                                        <td>
                                            <img src={`https://apprating.dev0app.com/uploads/${user.profile_picture}`}  className="rounded-circle" alt="Logo" style={{ maxWidth: '65px', margin: '0px 10px 0px 0px', borderWidth: "2px", borderStyle: 'solid', borderColor: roundRating(user.TotalRating) > 3 ? '#10CA00' : (roundRating(user.TotalRating) < 3 ? '#FF2A2A' : '#FF9900') }} />
                                            <Link to={`/dashboard/userDetail/${user.idUser}`} className="user-name">{user.name}</Link>    
                                            <span className="user-subhead">{user.role}</span>
                                            <div className='user-stars'>
                                                {[...Array(5)].map((star, index) => {
                                                    const ratingValue = index + 1;
                                                    const isFull = ratingValue <= roundRating(user.TotalRating);
                                                    const isHalf = ratingValue - 1 < roundRating(user.TotalRating) && ratingValue > roundRating(user.TotalRating);
                                                    const isEmpty = ratingValue > roundRating(user.TotalRating);

                                                    return (
                                                    <label key={ratingValue}>
                                                        <input type="radio" name="rating" value={ratingValue} style={{ display: 'none' }} disabled />
                                                        {isFull && <FontAwesomeIcon icon={faStar} style={{ cursor: 'pointer', color: '#FFE249' }} />}
                                                        {isHalf && <FontAwesomeIcon icon={faStarHalfAlt} style={{ cursor: 'pointer', color: '#FFE249' }} />}
                                                        {isEmpty && <FontAwesomeIcon icon={faStar} style={{ cursor: 'pointer', color: '#D9D9D9' }} />}
                                                    </label>
                                                    );
                                                })}
                                                <span className='rating_num fs-14'> {roundRating(user.TotalRating) ? `${roundRating(user.TotalRating)}/5` : '0/5'}</span>
                                            </div>
                                        </td>                                       
                                        <td className='email'>
                                            <a href="#" className='user-link'>{user.email}</a>
                                        </td>  
                                        <td className='bloc text-center'>
                                            {user.bloc_id !== null ? (
                                                <span>N°<b>{user.bloc_id}</b></span>
                                            ) : null }                                             
                                        </td>                                      
                                        <td className='text-center' style= {{width: '20%'}}>
                                            <Link to={`/dashboard/userDetail/${user.idUser}`} className="table-link">
                                                <span className="fa-stack fa-primary">
                                                    <FontAwesomeIcon icon={faEye} className="fa-stack-1x fa-inverse" />
                                                </span>
                                            </Link>
                                            <Link to={`/dashboard/userEdit/${user.idUser}`} className="table-link">
                                                <span className="fa-stack fa-primary">
                                                    <FontAwesomeIcon icon={faPencil} className="fa-stack-1x fa-inverse" />
                                                </span>
                                            </Link>
                                            <button type="button" onClick={() => handleUpdate(user.idUser)} className="delete-btn">
                                                <span className="fa-stack fa-danger">
                                                    <FontAwesomeIcon icon={faTrashAlt} className="fa-stack-1x fa-inverse" />
                                                </span>
                                            </button>
                                        </td>
                                    </tr>   
                                ))}                                                 
                                </tbody>
                            </table>
                        </div> 
                        <div className="pagination-container">
                            <ul className="pagination">
                                <li><a href="#" onClick={() => paginate(currentPage - 1)}><FontAwesomeIcon icon={faChevronLeft} /></a></li>
                                {Array.from({length: totalPageCount}, (_, index) => (
                                    <li key={index} className={currentPage === index + 1 ? 'active' : ''}>
                                        <a href="#" onClick={() => paginate(index + 1)}>{index + 1}</a>
                                    </li>
                                ))}
                                <li><a href="#" onClick={() => paginate(currentPage + 1)}><FontAwesomeIcon icon={faChevronRight} /></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>    
  )
}

export default Compte