import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.css';
import Menu from './Menu';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import useSession from '../useSession';


function UserEdit() {
    const { id } = useParams();
    const navigate = useNavigate();       
    const [password, setPassword] = useState(''); 
    const [showPassword, setShowPassword] = useState(false);

    /***** Start Vérification de la Session *****/ 
    const sessionData = useSession();
    if (sessionData) {
        console.log('Utilisation des données de session:', sessionData.email);       
        // axios.get(`https://apprating.dev0app.com/profile/${sessionData.email}`)
        //     .then(res => {                
        //         setUser(res.data[0]);                                   
        //     })
        //   .catch(error => console.error('Erreur lors de la récupération des informations de l\'utilisateur:', error));
    }
    else {
        console.log('Aucune session active');   
        navigate('/');           
    }
    /***** End Vérification de la Session *****/ 
    
    function generatePassword() {
        const length = 8; 
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"; // Character set
        let newPassword = "";
        for (let i = 0; i < length; i++) {
            newPassword += charset.charAt(Math.floor(Math.random() * charset.length));
        }
        return newPassword;
    }

    useEffect(() => {
    if (!id) {
      return; // Ne pas effectuer de requête si l'ID est nul
    }

    axios.get(`https://apprating.dev0app.com/users/${id}`)
        .then(res => {
          console.log(res);
          setValues({...values, name:res.data[0].name, email:res.data[0].email, sexe:res.data[0].sexe, profilePicture:res.data[0].profilePicture })
        })
        .catch(err => {
            console.error('There was an error!', err);
        });

    }, []);

    const [values, setValues] = useState({
        name: '',
        email: '',
        sexe: '',
        profilePicture:''
    })

    const handleUpdate = (event) => {
        event.preventDefault();        
        axios.put(`https://apprating.dev0app.com/update/${id}`, {
            name: values.name,
            email: values.email,
            sexe: values.sexe        
        })
        .then(res => {
            console.log(res);
            navigate('/dashboard/compte');
        })
        .catch(err => {
            console.error('There was an error!', err);
        });
    }
    
    // Function to handle password generation
    function handleGeneratePassword() {
        const newPassword = generatePassword();
        setPassword(newPassword);
    }
    
    // Function to toggle showing/hiding the password
    function togglePasswordVisibility() {
        setShowPassword(!showPassword);
    }

    // Function to handle profile picture change
    function handleProfilePictureChange(e) {
        setValues({...values, profilePicture: e.target.files[0]});
    }
        
  return (    
    <section style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap'}}>
    <Menu />
        <div className="container mt-4">            
            <div className='row justify-content-center'>
                <div className='col-md-8 text-center mb-2'>
                    <h2 className='heading-text'>Modifier un Compte</h2>
                    <h3 className='heading-detail mb-4'>Modifier les informations d'un compte de serveur</h3>
                </div>                
            </div>
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <div className="main-box clearfix">
                        <form action='#' className='signin-form' onSubmit={handleUpdate}>
                            <div className='form-group mb-3'>
                                <input type="text" className='form-control fs-19' placeholder='Nom' required style={{ margin: 'auto', display: 'block' }} value ={ values.name } onChange={e => setValues({...values,name: e.target.value})} />
                            </div>
                            <div className='form-group mb-3'>
                                <input type="file" className='form-control fs-19' placeholder='choisir image' style={{ margin: 'auto', display: 'block' }} onChange={handleProfilePictureChange} />
                                {values.profilePicture && <img src={URL.createObjectURL(values.profilePicture)} alt="Profile Preview" style={{ maxWidth: '100%', marginTop: '10px' }} />}
                            </div>   
                            <div className='form-group mb-3'>
                                <input type="email" className='form-control fs-19' placeholder='Email' required style={{ margin: 'auto', display: 'block' }}  value ={ values.email } onChange={e => setValues({...values,email: e.target.value})} />
                            </div>  
                            <div className='form-group mb-3'>
                                <div className="input-group">
                                    <input type={showPassword ? "text" : "password"} className='form-control fs-19' placeholder='Mot de passe' style={{ margin: 'auto', display: 'block' }} value={password} onChange={e => setPassword(e.target.value)} />
                                    <div className="input-group-append">
                                        <button className="btn btn-eye" type="button" onClick={togglePasswordVisibility}>
                                            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
                                        </button>
                                    </div>
                                </div>
                                <div className='col-md-12 text-right mb-2'>
                                    <button type="button" className="btn btn-wb" onClick={handleGeneratePassword}>Générer un mot de passe</button>
                                </div>
                            </div>  
                            <div className='form-group mb-3'>    
                                <select className="form-control fs-19" value={values.sexe} onChange={e => setValues({ ...values, sexe: e.target.value })} >
                                    {values.sexe === 'Femme' ? (
                                        <>
                                            <option value="Femme">Femme</option>
                                            <option value="Homme">Homme</option>
                                        </> 
                                    ) : (
                                        <>
                                            <option value="Homme">Homme</option>
                                            <option value="Femme">Femme</option>
                                        </>                                        
                                    )}
                                </select>                             
                            </div>                                                                                                    
                            <div className='form-group row'>
                                <button type="submit" className='col-md-12 btn btn-primary submit px-3 mb-3' style={{ margin: 'auto', display: 'block' }}>Enregistrer</button>
                                <Link to="/dashboard/compte" className='col-md-12 btn btn-secondary submit px-3 d-none d-sm-inline-block' style={{ margin: 'auto', display: 'block' }}>Annuler</Link>
                            </div>                            
                        </form>   
                    </div>
                </div>
            </div>            
        </div>
    </section> 
  )
}

export default UserEdit;