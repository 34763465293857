import React, { useState, useEffect } from 'react';
import Menu from './Menu';
import '../style/Dashboard/Bloc.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalfAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import useSession from '../useSession';


function Blocs() {
    const [users, setUsers] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedBloc, setSelectedBloc] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate(); 
    const { id } = useParams();

    /***** Start Vérification de la Session *****/ 
    const sessionData = useSession();
    if (sessionData) {
        console.log('Utilisation des données de session:', sessionData.email);       
        // axios.get(`https://apprating.dev0app.com/profile/${sessionData.email}`)
        //     .then(res => {                
        //         setUser(res.data[0]);                                   
        //     })
        //   .catch(error => console.error('Erreur lors de la récupération des informations de l\'utilisateur:', error));
    }
    else {
        console.log('Aucune session active');   
        navigate('/');           
    }
    /***** End Vérification de la Session *****/  
    
    useEffect(() => {
        axios.get('https://apprating.dev0app.com/BlocsUsers')
            .then(res => {
                setUsers(res.data);
            })
            .catch(error => {
                console.error('There was an error!', error);
            });
    }, []);

    function roundRating(num) {
        // Vérifie si la partie décimale est exactement 0.5
        if (num % 1 === 0.5) {
           return num; // Garde la valeur avec 0.5
        }
        return Math.round(num); // Arrondit selon la règle habituelle
    }

    const handleBlocClick = (bloc) => {
        setSelectedBloc(bloc);        
    };

    const handleGérerClick = () => {
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };

    // const handleCheckboxChange = (userId) => {
    //     setSelectedUsers(prevSelected => {
    //         if (prevSelected.includes(userId)) {
    //             return prevSelected.filter(id => id !== userId);
    //         } else {
    //             return [...prevSelected, userId];
    //         }
    //     });
    // };

    // const handleAjouterClick = () => {
    //     const selectedUsersData = users.filter(user => selectedUsers.includes(user.id));
    //     console.log(selectedUsersData); // Display selected users in console
    // };

    const handleCheckboxChange = (userId) => {
        setSelectedUsers(prevSelected => {
            if (prevSelected.includes(userId)) {
                return prevSelected.filter(id => id !== userId);
            } else {
                return [...prevSelected, userId];
            }
        });
    };

    const handleAjouterClick = async () => {
        try {
            // Send a POST request to your server with the selected users' data
            const res = await axios.post('https://apprating.dev0app.com/addSelectedUsers', {
                selectedUsers: selectedUsers,
                blocId: selectedBloc
            });           
            console.log(res.data); 
            // Affiche un message d'alerte indiquant que les utilisateurs ont été correctement affectés
            alert('Les utilisateurs ont été correctement affectés.');
            window.location.reload();
        } catch (err) {
            console.error('Error:', err);
        }
    };

    const handleCancel = (id) => {
        console.log(id);
        axios.put(`https://apprating.dev0app.com/AnnulerAffecation/${id}`)        
            .then(response => {
                console.log('User status updated successfully');    
                setShowModal(false); 
                window.location.reload();            
            })
            .catch(error => {
                // Handle error
                console.error('Error updating user status:', error);
            });
    };

    return (
        <section style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap'}}>
        <Menu />
            <div className="container">         
                <div className='row justify-content-center'>
                    <div className='col-md-8 text-center mb-2'>
                        <img src="/./././logo_bi.png" alt="Logo" style={{ maxWidth: '220px', margin: '0 auto 0px' }} />
                    </div>                                    
                </div>
                <div className='row list-blocs mb-7'>
                    <div className={`col-md-4 bloc ${selectedBloc === '1' ? 'selected' : ''}`} onClick={() => handleBlocClick('1')}>
                        <span className='text_bloc'>Bloc 1</span>
                    </div>
                    <div className={`col-md-4 bloc ${selectedBloc === '2' ? 'selected' : ''}`} onClick={() => handleBlocClick('2')}>
                        <span className='text_bloc'>Bloc 2</span>
                    </div>
                    <div className={`col-md-4 bloc ${selectedBloc === '3' ? 'selected' : ''}`} onClick={() => handleBlocClick('3')}>
                        <span className='text_bloc'>Bloc 3</span>
                    </div>
                </div>
                <div className='mb-3'>
                    <div className='form-group row'>
                        <button type="button" className='col-md-6 btn btn-primary submit px-3 mb-3 fs-22' style={{ margin: 'auto', display: 'block' }} onClick={handleGérerClick}>Gérer</button>
                    </div> 
                </div>

                <Modal show={showModal} onHide={handleCloseModal}>                    
                    <Modal.Body>
                        <div className='text-center mb-2'>
                            <img src="/./././logo_bi.png" alt="Logo" style={{ maxWidth: '145px', margin: '0 auto 0px' }} />
                            {selectedBloc && <p style={{ display: 'none'}}>{selectedBloc}</p>}
                        </div>
                        <div className='col-md-12'>
                            {users.map(user => (
                                <div className='user d-flex justify-content-between pb-4 col-md-12'>
                                    <div className='col-md-7 detail_user' key={user.idUser} style={{ paddingLeft: '0px', opacity: user.statut == 1 ? 0.6 :  1 }}>                              
                                        <img src={`https://apprating.dev0app.com/uploads/${user.profile_picture}`}  className="rounded-circle" alt="Logo" style={{ maxWidth: '65px', margin: '0px 10px 0px 0px', borderWidth: "2px", borderStyle: 'solid', borderColor: roundRating(user.TotalRating) > 3 ? '#10CA00' : (roundRating(user.TotalRating) < 3 ? '#FF2A2A' : '#FF9900') }} />
                                        <div>                                            
                                            <p className='username'>{user.name}</p>   
                                            <div className='t2'>
                                                {[...Array(5)].map((star, index) => {
                                                    const ratingValue = index + 1;
                                                    const isFull = ratingValue <= roundRating(user.TotalRating);
                                                    const isHalf = ratingValue - 1 < roundRating(user.TotalRating) && ratingValue > roundRating(user.TotalRating);
                                                    const isEmpty = ratingValue > roundRating(user.TotalRating);

                                                    return (
                                                    <label key={ratingValue}>
                                                        <input type="radio" name="rating" value={ratingValue} style={{ display: 'none' }} disabled />
                                                        {isFull && <FontAwesomeIcon icon={faStar} style={{ cursor: 'pointer', color: '#FFE249' }} />}
                                                        {isHalf && <FontAwesomeIcon icon={faStarHalfAlt} style={{ cursor: 'pointer', color: '#FFE249' }} />}
                                                        {isEmpty && <FontAwesomeIcon icon={faStar} style={{ cursor: 'pointer', color: '#D9D9D9' }} />}
                                                    </label>
                                                    );
                                                })}
                                                <span className='rating_num fs-14'> {roundRating(user.TotalRating) ? `${roundRating(user.TotalRating)}/5` : '0/5'}</span>
                                            </div>
                                        </div>
                                    </div>                                    
                                   
                                    <div className='col-md-5 d-flex justify-content-end'>
                                        {/* {user.bloc_id === null ? ( */}
                                        {user.statut !== 1 ? (
                                            <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={`check-${user.idUser}`}
                                            onChange={() => handleCheckboxChange(user.idUser)}
                                            />
                                        ) : 
                                        <>       
                                            <p className='mb-0'><span style={{ fontWeight: '600'}}>B</span>{user.bloc_id}</p>                                     
                                            <button type="button" onClick={() => handleCancel(user.idUser)} className="btn btn-light- py-0" style={{ paddingRight: '6px' }}>
                                                <span className="fa-stack d-flex align-items-center justify-content-center fa-danger" style={{ height: '1.56em', width: '1.56em'}}>
                                                    <FontAwesomeIcon icon={faTimes} className="fa-stack-1x1 fa-inverse" />
                                                </span>
                                            </button>
                                        </>
                                            
                                        }                                        
                                    </div>
                                    
                                </div>                 
                            ))}
                        </div>  
                        <div className='form-group row'>
                            <button type="submit" className='col-md-6 btn btn-primary submit px-3 mt-2 mb-3' style={{ margin: 'auto', display: 'block', lineHeight: '23px' }} onClick={handleAjouterClick}>Ajouter</button>
                        </div>                       
                    </Modal.Body>                    
                </Modal>
            </div>
        </section>
    
    )
}

export default Blocs